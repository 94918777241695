<template>
  <div class="w-full pb-8 mx-auto">
    <div v-if="candidate">
      <div class="relative w-full max-w-screen-xl px-4 py-8 mx-auto rounded-lg">
        <section aria-labelledby="candidate-profile">
          <section class="mb-4">
            <div class="overflow-hidden bg-white rounded-lg shadow">
              <h1
                id="candidate-profile"
                class="sr-only"
              >
                Candidate Profile
              </h1>

              <div class="p-6 bg-white">
                <div class="text-center sm:flex sm:items-center sm:justify-between sm:text-left">
                  <ProfileHeader
                    :profile="profile"
                    :candidate="candidate"
                  >
                    <template v-slot:name>
                      {{ candidateName }}
                    </template>
                    <template v-slot:email>
                      {{ candidate.email }}
                    </template>
                  </ProfileHeader>

                  <div class="flex justify-center mt-4 space-x-6 sm:mt-0 sm:text-right sm:justify-end">
                    <DownloadCandidateProfileButton
                      v-if="activeTab !== 'profile' && examsAndAttempts.length"
                      :candidate="candidate"
                      class="text-sm"
                    />
                    <EditCandidateButton
                      class="text-sm"
                      :candidate="candidate"
                      @candidateUpdated="candidateUpdated()"
                    />
                    <DeleteCandidateButton
                      class="text-sm"
                      :candidates="[...candidate]"
                      @candidates-deleted="candidatesDeleted()"
                    />
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-1 border-t border-gray-200 divide-y divide-gray-200 bg-gray-50 sm:grid-cols-2 sm:divide-y-0 sm:divide-x">
                <div class="px-6 py-4 md:py-0 md:px-0">
                  <ProfileHeaderTabs
                    :active-tab="activeTab"
                    @switchToTab="activeTab = $event"
                  />
                </div>
              </div>
            </div>
          </section>

          <div
            v-if="activeTab === 'profile'"
            class="grid items-start grid-cols-1 gap-4 lg:grid-cols-3"
          >
            <ProfileGrid :profile="profile" />

            <ExamSummaries
              class="grid grid-cols-1 col-span-1 gap-4"
              :exams-and-attempts="examsAndAttempts"
              @go-to-aptitude-test="handleViewAptitudeTest($event)"
              @activeTab="activeTab = $event"
            />
          </div>

          <PersonalQuestionsAnswers
            v-if="activeTab === 'qa'"
            :candidate="candidate"
            class="overflow-hidden bg-white rounded-lg shadow"
          />

          <CandidateResultsDetails
            :active-tab="activeTab"
            :candidate="candidate"
            :exams="examsAndAttempts"
          />
        </section>
      </div>
    </div>

    <div
      v-else
      class="pt-8"
    >
      <Loader />
    </div>
  </div>
</template>

<script>
import Loader from '@components/Loader'
import DeleteCandidateButton from '@components/Candidates/DeleteCandidateButton'
import EditCandidateButton from '@components/Candidates/EditCandidateButton'
import ProfileHeaderTabs from '@components/Candidates/ProfileHeaderTabs'
import DownloadCandidateProfileButton from '@components/Candidates/DownloadCandidateProfileButton'
import CandidateResultsDetails from '@components/Candidates/CandidateResultsDetails'
import PersonalQuestionsAnswers from '@components/CandidateWizardProfile/PersonalQuestionsAnswers'

import ProfileHeader from '@components/CandidateWizardProfile/ProfileHeader'
import ProfileGrid from '@components/CandidateWizardProfile/ProfileGrid'
import ExamSummaries from '@components/CandidateWizardProfile/ExamSummaries'

import { mapGetters } from 'vuex'

export default {
  components: {
    Loader,
    DeleteCandidateButton,
    EditCandidateButton,
    ProfileHeaderTabs,
    DownloadCandidateProfileButton,
    CandidateResultsDetails,
    ProfileHeader,
    ProfileGrid,
    ExamSummaries,
    PersonalQuestionsAnswers
  },

  props: {
    candidate: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      activeTab: 'aptitude'
    }
  },

  page() {
    return {
      title: this.candidateName
    }
  },

  computed: {
    ...mapGetters({
      haveExamsLoaded: 'exams/haveExamsLoaded',
      exams: 'organisations/exams',
      maskedAttributes: 'organisations/maskedAttributes'
    }),

    /**
     * @return {Object}
     */
    profile() {
      if (!this.candidate) {
        return null
      }

      return this.candidate.profile
    },

    /**
     * @return {Array}
     */
    examsAndAttempts() {
      if (!this.exams) {
        return []
      }

      let examsAndAttempts = []

      this.exams.forEach(exam => {
        this.candidate.attempts
          .filter(attempt => attempt.examSlug === exam.slug)
          .forEach(attempt => {
            examsAndAttempts.push({
              ...exam,
              attempt: attempt
            })
          })
      })

      return examsAndAttempts.sort((a, b) => {
        return Object.entries(b.attempt).length !== 0 ? 1 : -1
      })
    },

    /**
     * @return {string}
     */
    candidateName() {
      if (!this.candidate) {
        return null
      }
      return this.candidate.firstName + ' ' + this.candidate.surname
    }
  },

  mounted() {
    if (this.candidate && !this.profile) {
      this.activeTab = 'aptitude'
    }
  },

  methods: {
    /**
     * A candidate has been deleted
     */
    candidatesDeleted() {
      this.$router.go(-1)
    },

    /**
     * A candidate has been deleted
     */
    candidateUpdated() {
      this.$emit('candidateUpdated')
    },

    handleViewAptitudeTest(examSlug) {
      this.activeTab = 'aptitude'
      this.$nextTick(() => document.querySelector(examSlug).scrollIntoView({ block: 'center' }))
    }
  }
}
</script>

<template>
  <div >
    <CandidateProfileEnhanced
      :candidate="candidate"
      @candidateUpdated="fetchCandidate()"
    />
  </div>
</template>

<script>
import CandidateProfileEnhanced from '@components/Candidates/CandidateProfileEnhanced'
import organisationCandidatesApi from '@api/organisationCandidates'

import { mapGetters } from 'vuex'

export default {
  components: {
    CandidateProfileEnhanced
  },

  data() {
    return {
      candidate: null
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId'
    })
  },

  created() {
    this.fetchCandidate()
  },

  methods: {
    /**
     * Fetch the candidate based on the candidate id
     */
    fetchCandidate() {
      this.candidate = null

      return organisationCandidatesApi.candidate(this.organisationId, this.$route.params.id)
        .then(candidate => {
          this.candidate = candidate
        })
        .catch(error => {
          this.candidate = null
          throw error
        })
    }
  }
}
</script>

<template>
  <ul>
    <template v-if="examsAndAttempts.length">
      <li
        v-for="(exam, index) in examsAndAttempts"
        :key="index"
        class="cursor-pointer lg:col-span-1 group"
      >
        <a
          v-if="isAptitudeExam(exam)"
          class="block"
          href="javascript:;"
          @click="$emit('go-to-aptitude-test', `#${exam.slug}`)"
        >
          <div
            class="px-4 py-5 duration-200 ease-out bg-white shadow sm:rounded-lg sm:px-6 hover:shadow-none"
          >
            <header class="flex justify-between">
              <h2 class="text-lg font-medium">
                {{ exam.name }}
              </h2>
              <span class="opacity-0 inline-flex items-center px-2.5 py-0.5 rounded-lg text-xs font-medium bg-gray-100 duration-200 ease-out group-hover:opacity-75">
                View details
              </span>
            </header>
            <div class="mt-8">
              <div class="items-center justify-between leading-none sm:flex">
                <div class="flex flex-col w-full">
                  <div
                    class="w-full h-2 mt-4 bg-gray-300 sm:mt-0"
                  >
                    <div
                      :class="['h-full', scoreColor(exam.attempt.percentageBand, 'bg')]"
                      :style="`width: ${exam.attempt.scorePercentage}%`"
                    >
                    </div>
                  </div>
                  <p :class="['text-xs tracking-wider mt-3', scoreColor(exam.attempt.percentageBand)]">
                    {{ exam.attempt.bracket }}
                  </p>
                </div>
                <div :class="['flex items-center md:justify-end w-24 mt-4 sm:mt-0 sm:ml-4', scoreColor(exam.attempt.percentageBand)]">
                  <span class="text-5xl">{{ exam.attempt.scorePercentage }}</span><span class="ml-1 text-lg">%</span>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a
          v-if="exam.name === 'Personality'"
          href="javascript:;"
          class="block px-4 py-5 duration-200 ease-out bg-white shadow sm:rounded-lg sm:px-6 hover:shadow-none"
          @click="$emit('activeTab', 'personality')"
        >
          <header class="flex justify-between">
            <div>
              <h2 class="text-lg font-medium">
                {{ exam.name }} Scores
              </h2>
              <span class="w-1/4 mr-3 text-sm font-medium text-gray-500">Top Five</span>
            </div>

            <span class="opacity-0 inline-flex items-center px-2.5 py-0.5 rounded-lg text-xs font-medium bg-gray-100 duration-200 ease-out group-hover:opacity-75">
              View details
            </span>
          </header>

          <div class="mt-8 space-y-6">
            <div
              v-if="personalityTopFive.length"
              class="flex items-center"
            >
              <div class="flow-root">
                <ul class="-mb-6 text-sm">
                  <li
                    v-for="(entry, index) in personalityTopFive"
                    :key="index"
                  >
                    <div class="relative pb-6">
                      <span
                        v-if="index !== 4"
                        class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                        aria-hidden="true"
                      ></span>
                      <div class="relative flex space-x-3">
                        <div>
                          <span class="flex items-center justify-center w-8 h-8 bg-gray-400 rounded-full ring-8 ring-white">
                            {{ index + 1 }}
                          </span>
                        </div>
                        <div class="min-w-0 flex-1 pt-1.5 font-medium">
                          {{ entry.name }}
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </a>

        <a
          v-if="exam.name === 'Drives'"
          href="javascript:;"
          class="block px-4 py-5 duration-200 ease-out bg-white shadow sm:rounded-lg sm:px-6 hover:shadow-none"
          @click="$emit('active-tab', 'Drives')"
        >
          <header class="flex justify-between">
            <h2 class="text-lg font-medium">
              {{ exam.name }}
            </h2>
            <span class="opacity-0 inline-flex items-center px-2.5 py-0.5 rounded-lg text-xs font-medium bg-gray-100 duration-200 ease-out group-hover:opacity-75">
              View details
            </span>
          </header>
          <div class="mt-8">
            <ul class="flex flex-col mt-1 -mx-2 text-sm">
              <li
                v-for="(score, drive, index) in drivesWithScoresSorted"
                :key="index"
                class="p-2"
              >
                <div class="inline-flex items-center px-3 py-0.5 rounded-lg text-sm font-medium bg-gray-200 text-gray-800">
                  <span class="mr-2">{{ titleCase(drive) }}</span>
                  <ul
                    id="star"
                    class="flex items-center space-x-1"
                  >
                    <li
                      v-for="(n, index) in score"
                      :key="index"
                    >
                      <Icon
                        class="w-4 h-4 text-gray-600"
                        view-box="0 0 20 20"
                      >
                        <StarStandard />
                      </Icon>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </a>
      </li>
    </template>
    <li
      v-else
      class="bg-gray-200 rounded-lg shadow"
    >
      <div class="p-6 bg-white rounded-lg">
        <h2 class="mb-8 text-lg font-medium">
          Test Summaries
        </h2>
        <div
          class="flex flex-col items-center justify-center h-32 text-sm text-gray-400 border-2 border-gray-200 border-dashed rounded-lg"
        >
          <p>Incomplete</p>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import Icon from '@components/Icons/Icon'
import StarStandard from '@components/Icons/StarStandard'
import scoreColor from '@utils/scoreColor'
import { titleCase } from '@utils/titleCase'

export default {
  components: {
    Icon,
    StarStandard
  },

  props: {
    examsAndAttempts: {
      type: Array,
      default: null
    }
  },

  data() {
    return {
      scoreColor,
      titleCase,
      personalityTraits: []
    }
  },

  computed: {
    /**
     * @return {Object}
     */
    personalityTopFive() {
      const personality = this.examsAndAttempts.find(exam => exam.examType === 'personality-tests')
      if (!personality) {
        return []
      }
      return personality.attempt.summary.slice().sort((a, b) => (b.score > a.score) ? 1 : -1).slice(0, 5)
    },

    /**
     * @return {Object}
     */
    drivesWithScoresSorted() {
      const drives = this.examsAndAttempts.find(exam => exam.name === 'Drives')
      if (!drives) {
        return
      }

      const drivesHaveScores = Object.entries(drives.attempt.scores)
        .sort(([, a], [, b]) => b - a)
      // eslint-disable-next-line
        .reduce((acc, [key, value]) => (value === 0 ? acc : (acc[key] = value, acc)), {})

      return drivesHaveScores
    }
  },

  methods: {
    isAptitudeExam(exam) {
      const aptitudeExams = [
        'skill',
        'verbal-reasoning',
        'numerical-reasoning',
        'logical'
      ]
      return aptitudeExams.includes(exam.examType) && exam.instruction_slug !== 'drives'
    }
  }
}
</script>
